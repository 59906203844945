<template>
  <div
    @touchmove.prevent
    class="xn-guide"
  >
    <img src="./img/downlogo.png" alt="" style="margin-left: calc(calc(100% - 84px) / 2);margin-top: 30%;"/>
    <h2 style="text-align:center;margin-top:3%;">
        智慧停车管理端
    </h2>
    <img @click="appDlios" src="./img/android.png" alt="" style="margin-left: calc(calc(100% - 108px) / 2);margin-top: 30%;"/>
    <div id="weixin-tip" @click="closeTip" v-show="wxTip">
      <p><img width="50%" src="./img/live_weixin.png" alt="微信打开" /></p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      wxTip: false,
    };
  },
  methods: {
    is_weixin() {
      var ua = navigator.userAgent.toLowerCase();
      if (
        ua.match(/MicroMessenger/i) == "micromessenger" ||
        ua.match(/WeiBo/i) == "weibo"
      ) {
        return true;
      } else {
        return false;
      }
    },
    closeTip() {
      this.wxTip = false;
    },
    appDlios() {
      // this.$toast('app未上线')
      if (this.is_weixin()) {
        this.wxTip = true;
      } else {
        window.location.href = window.location.protocol + "//" + window.location.host + '/acmbapk/AiParkBoss.apk';
      }
    },
  },
  mounted() {
  },
  created() {
  },
};
</script>
<style scoped>
.xn-guide {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 1000;
}
#weixin-tip {
  position: fixed;
	left:0; 
  top:0;
	background: rgba(0,0,0,0.8);
	bottom: 0;
	right: 0;
	z-index: 999;
}
#weixin-tip p {
  text-align: left;
	margin-top: 10%;
	padding:0 5%;
}
</style>
